import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { getInlineLoader } from "../Helpers";
import {
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup } from 'react-icons/md';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }



  useEffect(() => {
    init();
  }, [])



  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h1
                  >
                    <AiOutlineUser size={100} />

                    <span
                      style={{
                        display: "block",
                        fontSize: "18px"
                      }}
                      className="font-semi-bold"
                    >
                      +{appContext.currentUserData.phone}
                    </span>
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_PERSONAL_SETTINGS[appContext.language]}
                  </h6>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                  >
                    <BsPersonGear className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_UPDATE_USERNAME[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                  >
                    <MdOutlinePhonelinkSetup className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_CHANGE_PHONE_NUMBER[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                  >
                    <MdInfoOutline className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_UPDATE_PERSONAL_INFO[appContext.language]}
                    </h6>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_PAYMENT_SETTINGS[appContext.language]}
                  </h6>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'my-subscription',
                      })
                    }}
                  >
                    <MdOutlinePayment className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_SUBSCRIPTION_FEE_PAYMENTS[appContext.language]}
                    </h6>
                  </div>



                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}