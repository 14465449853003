import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";

export default function View({ data, size = 'small' }) {
  const appContext = useContext(AppContext);
  
  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "article",
      extraItem: data.id,
    });
  }

  if(size === 'big') {
    return (
      <div 
        className="OneArticle big" 
        onClick={view}  
        style={{
          backgroundImage: `url(${CDN_URL+data.cover})`
        }}
      >

        <div className="cover">
          <div
            className="title"
          >
            {data.title}
          </div>
        </div>
      </div>
    );
  } else if(size === 'small') {
    return (
      <div 
        className="OneArticle small" 
        onClick={view}  
      >

        <div 
          className="cover"
          style={{
            backgroundImage: `url(${CDN_URL+data.cover})`
          }}
        > 
        </div>

        <div
          className="title"
        >
          {data.title}
        </div>
      </div>
    );
  }
}
