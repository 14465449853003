import { useState, useEffect, createContext } from "react";
import { getMainView, getViewerView, getSideViewerView, callApi, getBottomViewerView } from "./Helpers";
import { useParams } from "react-router-dom";
import Viewer from "./components/Viewer";
import MainBody from "./components/MainBody";
import Backdrop from "./components/Backdrop";
import SideViewer from "./components/SideViewer";
import ConfirmDialog from "./components/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import MainMenuBtn from "./components/MainMenuBtn";
import Login from "./views/Login";
import OverlayLoader from "./components/OverlayLoader";
import MainLoader from "./components/MainLoader";
import BottomBar from "./components/BottomBar";
import BottomViewer from "./components/BottomViewer";

export const AppContext = createContext(null);

export default function App(props) {

  const [ready, setReady] = useState(false); //for checking if app is ready

  const { _navItem, _navSubItem, _navExtraItem, _navMoreItem } = useParams(); //for routing purposes

  const [navItem, setNavItem] = useState(_navItem); //routing 
  const [navSubItem, setNavSubItem] = useState(_navSubItem); //routing
  const [navExtraItem, setNavExtraItem] = useState(_navExtraItem); //routing
  const [navMoreItem, setNavMoreItem] = useState(_navMoreItem); //routing

  const [showViewer, setShowViewer] = useState(false); //controlling the display of Viewer component
  const [viewerView, setViewerView] = useState(null); //the view to be shown in viewer

  const [showSideViewer, setShowSideViewer] = useState(false); //controlling the display of SideViewer component
  const [sideViewerView, setSideViewerView] = useState(null); //the view to be shown in viewer

  const [showBottomViewer, setShowBottomViewer] = useState(false); //controlling the display of BottomViewer component
  const [bottomViewerView, setBottomViewerView] = useState(null); //the view to be shown in viewer

  const [mainView, setMainView] = useState(null); //the view tobe shown in MainBody

  const [showOverlayLoader, setShowOverlayLoader] = useState(false); //controlling the display of OverlayLoader

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmDialogAction, setConfirmDialogAction] = useState("");

  const [isSideBarOpen, setIsSideBarOpen] = useState(false); //controls open and close for sidebar in mobile devices

  const [auth, setAuth] = useState(false); //track user authorization status

  //currentSession
  const [currentUserData, setCurrentUserData] = useState(null);
  const [theme, setTheme] = useState('default');
  const [language, setLanguage] = useState("en");

  //App level data
  const [articlesFeed, setArticlesFeed] = useState(null);
  const [storiesFeed, setStoriesFeed] = useState(null);

  async function init() {
    /**
     * Initialize the app here
     */

    setReady(false);

    await authCheck().then(async (_auth) => {
      if (_auth) {
        await getCurrentUserData();
      }
    })


    //turn off loaders after initialization
    setReady(true);
  }

  function navBack() {
    window.history.back();
    setShowOverlayLoader(false);
  }

  function tellError(msg) {
    toast.error(msg);
  }

  function tellInfo(msg) {
    toast.info(msg);
  }

  function tellWarning(msg) {
    toast.warn(msg);
  }

  function tellMessage(msg) {
    toast.success(msg);
  }

  function refresh() {
    /**
     * This function refreshes the whole app
     */
    window.location.reload(); //remember to optimize
  }

  function navTo(nav) {
    /**
     * This function handles navigation inside the app
     * Utilizing Hash based routing
     * nav is the object supporting the following keys: item, subItem, extraItem, moreItem
     */
    if (nav) {
      //..

      let url = '';
      if (nav.item) {
        url = `#/${nav.item}/`
      }

      if (nav.subItem) {
        url += `${nav.subItem}/`
      }

      if (nav.extraItem) {
        url += `${nav.extraItem}/`
      }

      if (nav.moreItem) {
        url += `${nav.moreItem}/`
      }

      window.location.href = url;
      //..
    }
  }

  async function getCurrentUserData() {
    await callApi('get-current-user-data', {}).then(response => {
      if (response.status === 1) {
        setCurrentUserData(response.data);
      }
    })
  }

  function authCheck() {
    return new Promise(async resolve => {
      await callApi('auth-check', {}).then(response => {
        if (response.status === 1) {
          setAuth(true);
          resolve(true)
        } else {
          setAuth(false);
          resolve(false);
        }
      })
    })
  }

  function activateDialog(params) {
    let {
      message,
      onConfirm
    } = params;
    setConfirmDialogAction(() => { return onConfirm });
    setConfirmDialogMessage(message)
    setShowConfirmDialog(true);
  }

  async function getArticlesFeed() {
    await callApi('get-articles-feed', {}).then(response => {
      if (response.status === 1) {
        setArticlesFeed(response.data);
      }
    })
  }

  async function getStoriesFeed() {
    await callApi('get-stories-feed', {}).then(response => {
      if (response.status === 1) {
        setStoriesFeed(response.data);
      }
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (isSideBarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSideBarOpen])

  useEffect(() => {
    setNavItem(_navItem);
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
    setNavMoreItem(_navMoreItem);
  }, [_navItem, _navSubItem, _navExtraItem, _navMoreItem])

  useEffect(() => {
    if (currentUserData) {
      //handle current user data changes here
    }
  }, [currentUserData])


  useEffect(() => {

    //check for viewers
    if (navItem === 'view') {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(appContext));

      //hide other viewers
      setShowSideViewer(false)
      setSideViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'side-view') {
      //activate viewer
      setShowSideViewer(true);
      setSideViewerView(getSideViewerView(appContext));

      //hide other viewers
      setShowViewer(false)
      setViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'bottom-view') {
      //activate viewer
      setShowBottomViewer(true);
      setBottomViewerView(getBottomViewerView(appContext));

      //do not hide other views

    } else {
      //just set normal views
      setShowViewer(false);
      setShowSideViewer(false)

      setViewerView(null);
      setSideViewerView(null);

      const _mainView = getMainView(appContext)
      if (_mainView) {
        setMainView(_mainView);
      }
    }
  }, [navItem, navSubItem, navExtraItem, navMoreItem]);

  const appContext = {
    refresh,
    navTo,
    mainView,
    viewerView,
    setShowOverlayLoader,
    navItem,
    navSubItem,
    navExtraItem,
    navMoreItem,
    setShowViewer,
    showViewer,
    navBack,
    showSideViewer,
    setShowSideViewer,
    sideViewerView,
    activateDialog,
    setShowConfirmDialog,
    confirmDialogAction,
    confirmDialogMessage,
    showConfirmDialog,
    tellError,
    tellInfo,
    tellMessage,
    tellWarning,
    isSideBarOpen,
    setIsSideBarOpen,
    showOverlayLoader,
    auth,
    currentUserData,
    theme,
    language,
    articlesFeed,
    getArticlesFeed,
    storiesFeed,
    getStoriesFeed,
    getCurrentUserData,
    bottomViewerView,
    showBottomViewer,
  }

  if (ready) {
    if (auth) {
      if (currentUserData) {
        return (
          <AppContext.Provider value={appContext}>
            <MainBody />
            <TopBar />
            <Viewer />
            <SideViewer />
            <ConfirmDialog />
            <OverlayLoader />
            <BottomViewer />
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          </AppContext.Provider>
        )
      } else {
        return (
          <AppContext.Provider value={appContext}>
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
            <MainLoader />
          </AppContext.Provider>
        )
      }
    } else {
      return (
        <AppContext.Provider value={appContext}>
          <OverlayLoader />
          <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          <Login />
        </AppContext.Provider>
      )
    }
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
        <MainLoader />
      </AppContext.Provider>
    )
  }
}