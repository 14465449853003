export const STR_APP_NAME = {
  en: "Chuo cha Mauzo",
  sw: "Chuo cha Mauzo",
};

export const STR_HEALTH = {
  en: "health",
  sw: "afya",
}

export const STR_RELATIONSHIPS = {
  en: "relationships",
  sw: "mahusiano",
}

export const STR_STORIES = {
  en: "stories",
  sw: "masimulizi",
}

export const STR_COURSES = {
  en: "Courses",
  sw: "Kozi",
}


export const STR_GET_STARTED = {
  en: "get started",
  sw: "anza sasa",
}

export const STR_CONTINUE = {
  en: "continue",
  sw: "endelea",
}

export const STR_ENTER = {
  en: "enter",
  sw: "ingia",
}

export const STR_ENTER_YOUR_PHONE_NUMBER = {
  en: "Enter your phone number",
  sw: "Ingiza namba yako ya simu",
}

export const STR_PHONE_NUMBER = {
  en: "Phone number",
  sw: "Namba ya simu",
}

export const STR_ENTER_OTP = {
  en: "Enter the OTP sent to your phone",
  sw: "Ingiza OTP uliotumiwa kwenye simu",
}

export const STR_PLEASE_WAIT = {
  en: "Please wait..",
  sw: "Tafadhali subiri..",
}

export const STR_INVALID_PHONE = {
  en: "Invalid phone number",
  sw: "Namba ya simu sio sahihi",
}

export const STR_INVALID_OTP = {
  en: "Invalid OTP",
  sw: "OTP sio sahihi",
}

export const STR_WELCOME = {
  en: "Welcome..",
  sw: "Karibu..",
}

export const STR_CHANGE_PHONE = {
  en: "Change Phone",
  sw: "Badili Simu",
}

export const STR_RESEND_OTP = {
  en: "Resend OTP",
  sw: "Tuma tena OTP",
}

export const STR_MENU_HOME = {
  en: "Home",
  sw: "Nyumbani",
}

export const STR_MENU_DATING = {
  en: "Dating",
  sw: "Mahusiano",
}

export const STR_MENU_DOCTORS = {
  en: "Doctors",
  sw: "Madaktari",
}

export const STR_MENU_INBOX = {
  en: "Inbox",
  sw: "Ujumbe",
}

export const STR_MENU_ARTICLES = {
  en: "Articles",
  sw: "Makala",
}

export const STR_MENU_STORIES = {
  en: "Stories",
  sw: "Simulizi",
}

export const STR_MENU_UTILITIES = {
  en: "Utilities",
  sw: "Visaidizi",
}

export const STR_LATEST_ARTICLES = {
  en: "Latest Articles",
  sw: "Makala Mpya",
}

export const STR_MORE_ARTICLES = {
  en: "More Articles",
  sw: "Makala Zaidi",
}

export const STR_LATEST_STORIES = {
  en: "Latest Stories",
  sw: "Simulizi Mpya",
}

export const STR_LATEST_COURSES = {
  en: "Latest Courses",
  sw: "Kozi Mpya",
}

export const STR_MORE_STORIES = {
  en: "More Stories",
  sw: "Simulizi Zaidi",
}

export const STR_MORE_COURSES = {
  en: "More Courses",
  sw: "Kozi Zaidi",
}

export const STR_PERSONAL_SETTINGS = {
  en: "Personal Settings",
  sw: "Mipangilio Binafsi",
}

export const STR_UPDATE_USERNAME = {
  en: "Update username",
  sw: "Badili/Weka jina",
}

export const STR_CHANGE_PHONE_NUMBER = {
  en: "Change phone number",
  sw: "Badili namba ya simu",
}

export const STR_UPDATE_PERSONAL_INFO = {
  en: "Update personal information",
  sw: "Badili/Weka taarifa binafsi",
}

export const STR_PAYMENT_SETTINGS = {
  en: "Payments",
  sw: "Malipo",
}

export const STR_SUBSCRIPTION_FEE_PAYMENTS = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_CONSULTATION_FEE_PAYMENTS = {
  en: "Doctor's consultation fee payment",
  sw: "Malipo ya kuwasiliana na daktari",
}

export const STR_MY_SUBSCRIPTION = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_SUBSCRIPTION_HAS_EXPIRED = {
  en: "Subscription has expired",
  sw: "Kifurushi kimeisha",
}

export const STR_PURCHASE_SUBSCRIPTION_PACKAGE = {
  en: "Purchase a subscription package to continue using this app",
  sw: "Nunua kifurushi cha matumizi ili kuendelea kutumia App hii",
}

export const STR_SELECT = {
  en: "Select",
  sw: "Chagua",
}

export const STR_COMPLETE_PAYMENT = {
  en: "Complete Payment",
  sw: "Kamilisha Malipo",
}

export const STR_PAY_WITH_CARD = {
  en: "Pay with Credit Card",
  sw: "Lipia kwa Kadi",
}

export const STR_PAY_WITH_MOBILE = {
  en: "Pay with Mobile Network",
  sw: "Lipia kwa Mtandao wa Simu",
}

export const STR_SELECT_PAYMENT_METHOD = {
  en: "Select payment method",
  sw: "Chagua njia ya kufanya malipo",
}

export const STR_ENTER_PHONE_NUMBER_FOR_PAYMENT = {
  en: "Enter phone number you want to use for this payment. All Tanzanian networks are supported.",
  sw: "Ingiza namba ya simu utakayofanyia malipo. Tunapokea kutoka mitandao yote ya Tanzania",
}

export const STR_FINALIZE_PAYMENT_CARD = {
  en: "Please wait, you'll soon be redirected to complete the payment with your card.",
  sw: "Tafadhali subiri utaelekezwa kwenye kiungo cha kufanyia malipo kwa kadi yako",
}

export const STR_FINALIZE_PAYMENT_MOBILE = {
  en: "Push USSD was sent to your phone please check and finalize the payment",
  sw: "Tafadhali angalia simu yako ili kukamilisha malipo kwa USSD Push",
}

export const STR_BUY_NOW = {
  en: "Buy Now",
  sw: "Nunua Sasa",
}

export const STR_DAYS = {
  en: "Days",
  sw: "Siku",
}

export const STR_FROM = {
  en: "From",
  sw: "Kuanzia",
}

export const STR_TO = {
  en: "To",
  sw: "Hadi",
}

export const STR_PRICE = {
  en: "Price",
  sw: "Bei",
}

export const STR_PASSWORD = {
  en: "Password",
  sw: "Nenosiri",
}

export const STR_CONFIRM = {
  en: "Confirm",
  sw: "Hakiki",
}

export const STR_INVALID_PASSWORD = {
  en: "Invalid Password, password must have atleast 8 characters",
  sw: "Nenosiri angalau liwe na muunganiko wa namba na herufi angalau 8",
}

export const STR_INVALID_CONFIRM_PASSWORD = {
  en: "Please confirm your password correctly",
  sw: "Tafadhali hakiki nenosiri lako kwa usahihi",
}

export const STR_CHANGE_LANGUAGE = {
  en: "Tumia Swahili",
  sw: "Change to English",
}

export const STR_FORGOT_PASSWORD = {
  en: "Forgot Password",
  sw: "Nimesahau Nenosiri",
}

export const STR_NO = {
  en: "No",
  sw: "Hapana",
}

export const STR_YES = {
  en: "Yes",
  sw: "Ndio",
}

export const STR_BOND_CALCULATOR = {
  en: "Bonds Calculator",
  sw: "Kikokotozi cha Hatifungani",
}

export const STR_LOAN_CALCULATOR = {
  en: "Loans Calculator",
  sw: "Kikokotozi cha Mkopo",
}

export const STR_UTT_CALCULATOR = {
  en: "UTT Investment Calculator",
  sw: "Kikokotozi cha uwekezaji wa UTT",
}

export const STR_BOOKS_LIBRARY = {
  en: "Books' Library",
  sw: "Maktaba ya Vitabu",
}

export const STR_ = {
  en: "",
  sw: "",
}