import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { STR_BUY_NOW, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_SUBSCRIPTION_HAS_EXPIRED } from "../Strings";

export default function View({ }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "my-subscription",
    });
  }

  return (
    <div
      className="card"

    >
      <div className="card-body text-center">
        <h6
          style={{
            fontSize: "32px",
          }}
          className="font-semi-bold text-secondary mb-4"
        >
          {STR_SUBSCRIPTION_HAS_EXPIRED[appContext.language]}
        </h6>

        <h6
          style={{
            fontSize: "16px",
          }}
          className=""
        >
          {STR_PURCHASE_SUBSCRIPTION_PACKAGE[appContext.language]}
        </h6>

        <button
          className="btn btn-rounded btn-padded btn-secondary mt-4 mb-4"
          onClick={view}
        >
          {STR_BUY_NOW[appContext.language]}
        </button>
      </div>
    </div>
  );
}
