import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_BOND_CALCULATOR,
  STR_BOOKS_LIBRARY,
  STR_LATEST_ARTICLES,
  STR_LATEST_STORIES,
  STR_LOAN_CALCULATOR,
  STR_MENU_ARTICLES,
  STR_MENU_STORIES,
  STR_MENU_UTILITIES,
  STR_MORE_ARTICLES,
  STR_MORE_STORIES,
  STR_UTT_CALCULATOR
} from "../Strings";
import OneArticle from '../ones/OneArticle';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_MENU_UTILITIES[appContext.language]}
              </h1>
            </div>

            <div className="col-md-4 col-6">
              <div
                className="card"
                style={{
                  height: "80px",
                }}
              >
                <div className="card-body">
                  <h6 style={{ fontSize: "16px" }} className="text-center font-semi-bold text-secondary">
                    {STR_BOND_CALCULATOR[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6">
              <div
                className="card"
                style={{
                  height: "80px",
                }}
              >
                <div className="card-body">
                  <h6 style={{ fontSize: "16px" }} className="text-center font-semi-bold text-secondary">
                    {STR_LOAN_CALCULATOR[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6">
              <div
                className="card"
                style={{
                  height: "80px",
                }}
              >
                <div className="card-body">
                  <h6 style={{ fontSize: "16px" }} className="text-center font-semi-bold text-secondary">
                    {STR_UTT_CALCULATOR[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6">
              <div
                className="card"
                style={{
                  height: "80px",
                }}
              >
                <div className="card-body">
                  <h6 style={{ fontSize: "16px" }} className="text-center font-semi-bold text-secondary">
                    {STR_BOOKS_LIBRARY[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}