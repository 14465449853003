import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { STR_DAYS, STR_FROM, STR_MY_SUBSCRIPTION, STR_PRICE, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_SELECT, STR_SUBSCRIPTION_HAS_EXPIRED, STR_TO } from "../Strings";
import { MdWarning } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

export default function View(props) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [subscriptionPackages, setSubscriptionPackages] = useState(null);


	async function init() {
		//run initializations here
		setReady(false);
		await appContext.getCurrentUserData();
		await getSubscriptionPackages();
		setReady(true);
	}

	async function getSubscriptionPackages() {
		await callApi("get-subscription-packages", {}).then(response => {
			if (response.status === 1) {
				setSubscriptionPackages(response.data);
			}
		})
	}

	function selectedPackage(packageId) {
		appContext.navTo({
			item: 'bottom-view',
			subItem: 'buy-subscription-package',
			extraItem: packageId,
		})
	}

	useEffect(() => {
		init();
	}, [])



	if (ready) {
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					overflow: "scroll",
				}}
				className="mNoScrollBar"
			>
				<div className="container">

					<div className="row">

						<div className="col-md-12">
							<h1
								className="mSectionTitle"
							>
								{STR_MY_SUBSCRIPTION[appContext.language]}
							</h1>
						</div>

						{
							(appContext.currentUserData.subscriptionStatus === 'active') ?
								<div className="col-md-12">
									<div className="card bg-primary">
										<div className="card-body">
											<h1
												style={{
													fontSize: "48px",
												}}
											>
												{appContext.currentUserData.subscriptionDuration}
												<span style={{ fontSize: "12px", display: "block" }}>{STR_DAYS[appContext.language]}</span>
											</h1>

											<div className="row">
												<div className="col-6">
													<h1
														style={{
															fontSize: "20px",

														}}
														className="mt-4"
													>
														{formatDate(appContext.currentUserData.subscriptionStart).substring(0, 10)}
														<span style={{ fontSize: "12px", display: "block" }}>{STR_FROM[appContext.language]}</span>
													</h1>
												</div>

												<div className="col-6 text-end">
													<h1
														style={{
															fontSize: "20px",

														}}
														className="mt-4"
													>
														{formatDate(appContext.currentUserData.subscriptionEnd).substring(0, 10)}
														<span style={{ fontSize: "12px", display: "block" }}>{STR_TO[appContext.language]}</span>
													</h1>
												</div>

												<div className="col-12 text-end">
													<h1
														style={{
															fontSize: "30px",

														}}
														className="mt-4 font-bold"
													>
														{formatMoney(appContext.currentUserData.subscriptionPrice)}
														<span className="" style={{ fontSize: "18px" }}> TZS</span>
													</h1>
												</div>

											</div>
										</div>
									</div>
								</div>
								:
								<>
									<div className="col-md-12">
										<div className="card bg-primary">
											<div className="card-body">
												<h1
													style={{
														fontSize: "18px",
														padding: "30px",
														textTransform: "uppercase",
													}}
													className="font-regular text-center"
												>
													<IoWarningOutline size={40} /> <br />
													{STR_SUBSCRIPTION_HAS_EXPIRED[appContext.language]}
												</h1>
											</div>
										</div>
									</div>

									<div className="col-md-12 mt-4">
										<label
											className="form-label"

										>
											{STR_PURCHASE_SUBSCRIPTION_PACKAGE[appContext.language]}
										</label>

										<div className="row">
											{
												(subscriptionPackages && subscriptionPackages.length > 0) ?
													subscriptionPackages.map((item, i) => {
														return (
															<div className="col-md-4" key={i}>
																<div
																	className="card"
																	onClick={() => selectedPackage(item.id)}
																>
																	<div className="card-body">
																		<h6 className="card-title">{item.title}</h6>
																		<h6
																			style={{
																				fontSize: "20px",
																			}}
																		>
																			{item.duration}
																			<span style={{ display: "block", fontSize: "12px", }}>Days</span>
																		</h6>

																		<h6
																			style={{
																				fontSize: "36px",
																			}}
																			className="text-end"
																		>
																			{formatMoney(item.price)}
																			<span style={{ display: "block", fontSize: "14px", }}>TZS</span>
																		</h6>

																		<button
																			className="btn btn-block btn-padded btn-secondary"
																		>
																			{STR_SELECT[appContext.language]}
																		</button>
																	</div>
																</div>
															</div>
														)
													})
													:
													<div className="col-md-12">
														<h6
															style={{
																fontSize: "14px",
															}}
														>
															No packages were found
														</h6>
													</div>
											}
										</div>
									</div>
								</>
						}


					</div>

				</div>


			</div>
		)
	} else {
		return (
			<div
				className="mSupportLoading"
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{getInlineLoader()}
			</div>
		);
	}


}