import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_LATEST_COURSES,
  STR_LATEST_STORIES,
  STR_MENU_STORIES,
  STR_MENU_UTILITIES,
  STR_MORE_COURSES,
  STR_MORE_STORIES
} from "../Strings";
import OneStory from '../ones/OneStory';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [latestStories, setLatestStories] = useState([]);
  const [moreStories, setMoreStories] = useState([]);


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getStoriesFeed();
    setReady(true);
  }

  function processFeed() {
    if (appContext.storiesFeed && appContext.storiesFeed.length > 0) {
      const latestIndexLimit = 5;
      const _latest = [];
      const _more = [];

      for (let i = 0; i < appContext.storiesFeed.length; i++) {
        const _article = appContext.storiesFeed[i];

        if (i < latestIndexLimit) {
          _latest.push(_article);
        } else {
          _more.push(_article);
        }
      }

      setLatestStories(_latest);
      setMoreStories(_more);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    processFeed();
  }, [appContext.storiesFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_LATEST_COURSES[appContext.language]}
              </h1>
            </div>

            {
              (latestStories && latestStories.length > 0) ?
                latestStories.map((item, i) => {
                  return (
                    <div
                      className="col-md-6"
                      key={i}
                    >
                      <OneStory data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No courses were found.
                </h6>
            }


            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_MORE_COURSES[appContext.language]}
              </h1>
            </div>

            {
              (moreStories && moreStories.length > 0) ?
                moreStories.map((item, i) => {
                  return (
                    <div
                      className="col-md-6"
                      key={i}
                    >
                      <OneStory data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No courses were found.
                </h6>
            }



          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}