import { useContext } from "react"
import { AppContext } from "../App"
import { MdLogout, MdMenu, MdNotifications, MdOutlineMessage, MdOutlineNotifications } from "react-icons/md";
import { IoChevronBack, IoNotificationsOutline, IoRefreshCircleOutline } from "react-icons/io5";
import { TbMessageDots } from "react-icons/tb";
import { callApi, setCookie } from "../Helpers";
import logo_worded_only from '../assets/logo_worded_only.png';
import { AiOutlineUser } from 'react-icons/ai';

export default function TopBar(props) {

  const appContext = useContext(AppContext);

  return (
    <div
      className="TopBar"
    >
      <div className="body mShadow3">
        <div
          className="container d-flex justify-content-between"
          style={{
            height: "100%",
          }}
        >


          <div
            className="mIcon text-start"
            onClick={appContext.navBack}
          >
            <IoChevronBack className="mSymbol" />
          </div>

          <img
            src={logo_worded_only}
            style={{
              width: "auto",
              height: "60%",
              margin: "0",
            }}
            className="align-self-center"
          />

          <div
            className="mIcon text-end"
          >
            <AiOutlineUser
              className="mSymbol"
              onClick={() => {
                appContext.navTo({
                  item: 'account',
                })
              }}
            />
          </div>

        </div>
      </div>
    </div>
  )
}