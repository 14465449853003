import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_LATEST_ARTICLES,
  STR_MENU_ARTICLES,
  STR_MENU_STORIES,
  STR_MENU_UTILITIES,
  STR_MORE_ARTICLES
} from "../Strings";
import OneArticle from '../ones/OneArticle';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [latestArticles, setLatestArticles] = useState([]);
  const [moreArticles, setMoreArticles] = useState([]);


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getArticlesFeed();
    setReady(true);
  }

  function processFeed() {
    if (appContext.articlesFeed && appContext.articlesFeed.length > 0) {
      const latestIndexLimit = 5;
      const _latest = [];
      const _more = [];

      for (let i = 0; i < appContext.articlesFeed.length; i++) {
        const _article = appContext.articlesFeed[i];

        if (i < latestIndexLimit) {
          _latest.push(_article);
        } else {
          _more.push(_article);
        }
      }

      setLatestArticles(_latest);
      setMoreArticles(_more);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    processFeed();
  }, [appContext.articlesFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_LATEST_ARTICLES[appContext.language]}
              </h1>
            </div>

            <div className="col-md-12">
              {
                (latestArticles && latestArticles.length > 0) ?
                  latestArticles.map((item, i) => {
                    return <OneArticle key={i} data={item} size="big" />
                  })
                  :
                  <h6
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    No articles were found.
                  </h6>
              }

            </div>


            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_MORE_ARTICLES[appContext.language]}
              </h1>
            </div>

            {
              (moreArticles && moreArticles.length > 0) ?
                moreArticles.map((item, i) => {
                  return (
                    <div
                      className="col-md-6"
                      key={i}
                    >
                      <OneArticle data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No articles were found.
                </h6>
            }



          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}